import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class SetPasswordGuard implements CanActivate {
  constructor() {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const userId = +route.paramMap.get('id');
    if (userId) {
      return true;
    } else {
      return false;
    }
  }
}
