export class AppConsts {

  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static adminBaseUrl: string;
  static TeamPermission = 'TeamPermission';
  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
  static passwordValidationPatteren = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
  static localeMappings: any = [];
  static displayNotificationKey = 'aplos-display-notification';
  static recentProjectsKey = 'recent#-projects.aplos-pro';

  // regular expression for url
  // tslint:disable-next-line:max-line-length
  static readonly urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  // static specialCharactersRemover = /^[a-zA-Z0-9][a-zA-Z0-9 ]+[a-zA-Z0-9]$/;
  // static errorMessageForSpecialCharacters = "Special Characters are not allowed and first character should not be space";
  static specialCharactersRemover = /^[a-zA-Z0-9.,-/:;()&@“‘][a-zA-Z0-9.,-/:;()&@“‘ ]+[a-zA-Z0-9.,-/:;()&@“‘]$/;
  static errorMessageForSpecialCharacters = 'Only these characters . , - / : ; () & @ “ ‘ are allowed';

  static readonly userManagement = {
    defaultAdminUserName: 'admin'
  };

  static readonly localization = {
    defaultLocalizationSourceName: 'myproject'
  };

  static readonly authorization = {
    encrptedAuthTokenName: 'enc_auth_token'
  };
}

export class FieldLimits {
  static readonly addressLength = {
    max: 255,
    min: 5
  };
  static readonly FullName = {
    max: 100,
    min: 3
  };
  static readonly Url = {
    max: 255,
    min: 3
  }
}

export class SelectedCountry {
  name:string;
  code: string;
  img: string;
}

export enum SaveManagerMode {
  Program,
  Project
}
export class PermissionNames {
  static pages_Account_Holder = 'Pages.AccountHolder';

  // Company Permission names
  static companyPermission = 'CompanyPermission';
  static createCompany = 'CreateCompany';
  static updateCompany = 'UpdateCompany';
  static deleteCompany = 'DeleteCompany';
  static viewCompany = 'ViewCompany';

  // Program permission names
  static programPermission = 'ProgramPermission';
  static createProgram = 'CreateProgram';
  static updateProgram = 'UpdateProgram';
  static deleteProgram = 'DeleteProgram';
  static viewProgram = 'ViewProgram';

  // Department Permission names
  static departmentPermission = 'DepartmentPermission';
  static createDepartment = 'CreateDepartment';
  static updateDepartment = 'UpdateDepartment';
  static deleteDepartment = 'DeleteDepartment';
  static viewDepartment = 'ViewDepartment';

  // Team Permission names
  static teamPermission = 'TeamPermission';
  static createTeam = 'CreateTeam';
  static updateTeam = 'UpdateTeam';
  static deleteTeam = 'DeleteTeam';
  static viewTeam = 'ViewTeam';

  // Project Permission names
  static projectPermission = 'ProjectPermission';
  static createProject = 'CreateProject';
  static updateProject = 'UpdateProject';
  static deleteProject = 'DeleteProject';
  static viewProject = 'ViewProject';

  // Resource Permission names
  static resourcePermission = 'ResourcePermission';
  static createResource = 'CreateResource';
  static updateResource = 'UpdateResource';
  static deleteResource = 'DeleteResource';
  static viewResource = 'ViewResource';

  // Customer Permission names
  static customerPermission = 'CustomerPermission';
  static createCustomer = 'CreateCustomer';
  static updateCustomer = 'UpdateCustomer';
  static deleteCustomer = 'DeleteCustomer';
  static viewCustomer = 'ViewCustomer';
}
