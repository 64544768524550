﻿import { Injectable } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";

@Injectable()
export class AppAuthService {
  logout(reload?: boolean): void {
    abp.auth.clearToken();
    localStorage.removeItem(AppConsts.displayNotificationKey);
    localStorage.removeItem(AppConsts.recentProjectsKey);

    if (reload !== false) {
      location.href = AppConsts.appBaseUrl;
      // location.href = 'http://localhost:4200/app/home';
    }
  }
  isLoggedIn() {
    const token = abp.auth.getToken();

    return token.length > 0;
  }
}
